// import

import type {NodeType, UrlNode} from '@arc/hub-model';
import type {NodePage} from 'libs/db';

import {capitalCase} from 'change-case';
import {max} from 'date-fns';

import {isStr} from '@arc/rambo';

// types

type PublishNode = Pick<UrlNode, 'meta'>;

// old

const ensureAbs = (url: string) => {
  return url.startsWith('http') ? url : process.env.RESOLVED_URL + url;
};

export const smartJoin = (arr: Array<string | null>) =>
  arr.filter(Boolean).join(' ');

export const parseImages = (imgs: unknown[]) =>
  imgs.reduce<Array<{url: string}>>((acc, url) => {
    if (isStr(url)) {
      acc.push({url: ensureAbs(url)});
    }

    return acc;
  }, []);

// export

export const withPaging = <T extends UrlNode>(
  page?: NodePage<T>,
): page is NodePage<T> =>
  !!page && page.last > 1;

export const getUpdateDate = (post: PublishNode) =>
  max([
    new Date(post.meta.updateDate),
    new Date(post.meta.publishDate),
  ]).toISOString();

export const getCreateDate = (post: PublishNode) =>
  max([
    new Date(post.meta.createDate),
    new Date(post.meta.publishDate),
  ]).toISOString();

export const getRollPrefix = (type: NodeType) => {
  return type === 'postTag' ? 'Tag' :
    type === 'supportTag' ? 'Tag' :
    type === 'person' ? 'Author' :
    capitalCase(type);
};
