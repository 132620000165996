// import

import type {UrlNode} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {styled} from '@linaria/react';

import {Link} from 'modules/Link';
import {Image} from 'modules/Media';

// types

type Props = ComponentProps<typeof Styled> & {
  node: Pick<UrlNode<{bannerImage?: string}>, 'meta'>;
  width?: number;
  height?: number;
};

// component

export function BannerImage(props: Props) {
  const {
    node: {meta: {title, link, bannerImage}},
    width = 2000, height = 1000, ...rest
  } = props;

  return bannerImage ? (
    <Styled {...rest} href={link}>
      <Image
        alt={title}
        height={height}
        layout="responsive"
        objectFit="cover"
        objectPosition="center"
        src={bannerImage}
        width={width} />
    </Styled>
  ) : null;
}

// styles

const Styled = styled(Link)`
display: block;
position: relative;
overflow: hidden;
z-index: 1;
border-radius: 0.25rem;
box-shadow: 0 0 0.5rem var(--black-25);

img {
  display: block;
  transition: transform 1000ms linear;
}

&:hover { img { transform: scale(1.1); } }
`;
