// import

import type {ComponentProps, FormEventHandler, ReactChild, Ref} from 'react';
import type {UseFormRegisterReturn, UseFormReturn} from 'react-hook-form';

import isEmail from 'is-email';
import {forwardRef, useRef} from 'react';
import {useForm} from 'react-hook-form';

import {cram} from '@arc/rambo';

import {usePostMessage} from 'modules/MessageList';

// types

type Props = ComponentProps<'form'> & UseSignupFormProps & {
  buttonText?: ReactChild;
  inputClass?: string;
  buttonClass?: string;
};

type UseSignupFormProps = {
  form: UseFormReturn<{email: string}>;
  ref: Ref<HTMLFormElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  inputProps: UseFormRegisterReturn & {
    placeholder: string;
  };
  buttonProps: {
    type: 'submit';
    disabled: boolean;
  };
};

// hooks

export function useSignupForm(groups: string[]): UseSignupFormProps {
  const form = useForm<{email: string}>();
  const ref = useRef<HTMLFormElement | null>(null);
  const postMessage = usePostMessage();

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      ref.current?.blur();
      form.reset();
      postMessage({
        color: 'green',
        text: 'Success! Check your inbox to confirm you\'re all set.',
      });
    } catch (err) {
      console.error(err);
      postMessage({
        color: 'red',
        text: 'Something went wrong. But don\'t worry! Our code monkey has been notified. He\'ll eat the bug and get you signed up manually.',
      });
    }
  }, (errs) => {
    if (errs.email) {
      postMessage({
        color: 'yellow',
        text: 'Please specify a valid email address.',
      });
    }
  });

  const inputProps = {
    ...form.register('email', {required: true, validate: isEmail}),
    placeholder: 'author@email.com' as const,
  };

  const buttonProps = {
    type: 'submit' as const,
    disabled: form.formState.isSubmitting,
  };

  return {form, ref, inputProps, buttonProps, onSubmit};
}

// component

export const SignupForm = forwardRef((
  props: Props,
  ref: Ref<HTMLFormElement>,
) => {
  const {form, placeholder = 'author@email.com', children, buttonText = 'Subscribe', inputClass, buttonClass, inputProps, buttonProps, ...rest} = props;

  return (
    <form {...rest} ref={ref}>
      {children}

      <input
        {...inputProps}
        className={cram(inputClass, {invalid: !!form.formState.errors.email})}
        placeholder={placeholder}
        type="email" />

      <button
        {...buttonProps}
        className={buttonClass}
        disabled={form.formState.isSubmitting}
        type="submit">
        {buttonText}
      </button>
    </form>
  );
});
