// import

import {CloudinaryServer, CloudinaryClient} from '@arc/visuals';

// export

export const privateCloud = new CloudinaryServer({
  cloud: process.env.CLOUDINARY_CLOUD_NAME,
  apiKey: process.env.CLOUDINARY_API_KEY,
  apiSecret: process.env.CLOUDINARY_API_SECRET,
});

export const publicCloud = new CloudinaryClient({
  cloud: process.env.CLOUDINARY_CLOUD_NAME,
});
