// import

import type {Transform} from '@arc/visuals';
import type {ImageLoader, ImageLoaderProps, ImageProps} from 'next/image';

import NextImage from 'next/image';
import {useMemo} from 'react';

import {isStr} from '@arc/rambo';

import {publicCloud} from 'libs/cloudinary';

// type

type Props = ImageProps & {
  loader?: ImageLoader;
  filename?: string;
};

type TransformFunc = (props: ImageLoaderProps) => Transform;

// vars

const cloud = process.env.CLOUDINARY_CLOUD_NAME;
const cloudinaryRoot = `https://res.cloudinary.com/${cloud}`;

// fns

const createLoader = (transFn: TransformFunc) => {
  return (props: ImageLoaderProps) => {
    return publicCloud.updateUrl(
      props.src,
      {trans: transFn(props)},
    ) ?? props.src;
  };
};

export const resizeLoader = createLoader(({src, width, quality}) => {
  return {
    fetch_format: src.endsWith('.svg') ? 'svg' : 'auto',
    crop: 'limit',
    width,
    quality: quality ?? 'auto',
  };
});

export const squareLoader = createLoader(({src, width, quality}) => {
  return {
    fetch_format: src.endsWith('.svg') ? 'svg' : 'auto',
    crop: 'fill',
    gravity: 'center',
    aspect_ratio: '1:1',
    width,
    quality: quality ?? 'auto',
  };
});

// hooks

export function useFriendlyImageSrc(
  src: Props['src'],
  filename: Props['filename'],
): Props['src'] {
  return useMemo(() => {
    if (!isStr(src) || !isStr(filename) || !src.includes(cloudinaryRoot)) {
      return src;
    }

    return publicCloud.updateUrl(src, {filename});
  }, [src, filename]);
}

// component

export function Image(props: Props) {
  const {loader = resizeLoader, src, filename, ...rest} = props;
  const newSrc = useFriendlyImageSrc(src, filename);

  return (
    <NextImage loader={loader} {...rest} src={newSrc} />
  );
}
